<template>
	<div class="main-contents input">
		
		<div class="tit">전체 프로젝트</div>
		<div class="search-box interval">
			<!-- <SelectComp type="select" class="wd05" :isAll="true" list="3:공급기업 지정,1:일반 구인" title="프로젝트 구분" v-model="input.projectDivCd"/>						
			<nbsp/> -->
			<SelectComp type="select" class="wd05" title="승인 상태" list="1:미승인,2:승인" :isAll="true" v-model="input.projectApprStatus" /><nbsp/>
			<SelectComp type="select" class="wd05" :isAll="true" list="0:모집중,4:제안중,2:수행예정,1:수행중,9:수행완료" title="프로젝트 상태" v-model="input.projectStatusTemp"/>						
			<nbsp/>
			<SelectComp type="select" class="" list="1:프로젝트명,2:프로젝트 담당자명,3:매니저명,4:수행사명" v-model="input.searchGubun"/>						
			<InputComp type="text" :placeholder="input.searchGubun == '1' ? '프로젝트명' : input.searchGubun == '2' ? '프로젝트 담당자명' : input.searchGubun  == '3' ? '매니저명' : '수행사명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')"/>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin total">
				<colgroup> <!-- 3, 32, 17, 11, 11, 8, 9, 9 -->
					<col width="3%">
					<col width="33.5%">
					<col width="12%">
					<col width="8%">
					<col width="11%">
					<col width="7%">
					<col width="7%">
					<col width="7%">
					<col width="7.5%">
					<col width="10%">
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>프로젝트명</th>
						<th>수행사명</th>
						<th>프로젝트담당</th>
						<th>매니저</th>
						<th>등록일</th>
						<th>모집 시작일</th>
						<th>수주일</th>
						<th>프로젝트 상태</th>
						<th>프로젝트 관리</th>
					</tr>
				</thead>
				<tbody>

					<tr v-for="(project, index) in projectList" :key="project.projectSeq">
						<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
						<td class="score">
							<div class="rc_tag" v-if="project.apprYn != 'Y'">
								<div class="rc_mark_red">미 승 인</div>
							</div>
							<div class="rc_tag" v-else>
								<div v-if="project.contractYyyymmdd == undefined || project.contractYyyymmdd == null" class="rc_mark_black">제 안 중</div>
								<div v-if="(project.recrStartYyyymmdd <= nowYyyymmdd && project.recrEndYyyymmdd >= nowYyyymmdd) && project.recrEndDateDiff >= 0" class="rc_mark">+모집중</div>
							</div>
							{{project.projectNm}}
						</td>
						<td class="score">{{project.corpNm}}</td>
						<td class="score name">
							<span v-if="project.corpMgrMberNm" @click="popupCorpMgrMber(project.corpMgrMberSeq)">{{project.corpMgrMberNm}} &#9654;</span>
							<span v-else>-</span>
						</td>
						<td class="score name">
							<span v-if="project.admMgrMberNm" @click="popupAdmMgerMber(project.admMgrMberSeq)">{{project.admMgrMberNm}} &#9654;</span>
							<span v-else-if="$store.state.userInfo.mberDivCd == '00'"><img src="/images/admin/agent_assign_btn.png" alt="매니저배정하기버튼" @click="setAgent(project.projectSeq, project.projectNm)"/></span>
							<span v-else>-</span>
						</td>
						<td class="score">{{project.regYyyymmdd}}</td>
						<td class="score">{{project.recrStartYyyymmdd| date('yyyy.MM.dd')}}</td>
						<td class="score" v-if="project.contractYyyymmdd == undefined || project.contractYyyymmdd == null">-</td><td class="score" v-else>{{project.contractYyyymmdd| date('yyyy.MM.dd')}}</td>
						<td class="score">
							<span v-if="project.projectStatus == 1"><div class="state pf"></div>수 행 중</span>
							<span v-else-if="project.projectStatus == 4"><div class="state us"></div>제 안 중</span>
							<span v-else-if="project.projectStatus == 2"><div class="state ep"></div>수행예정</span>
							<span v-else-if="project.projectStatus == 9"><div class="state cp"></div>수행완료</span>
						</td>
						<td class="score resume" @click="clickProjectInfo(project.projectSeq)"><div class="btn">프로젝트 관리 &#9654;</div></td>
					</tr>
					
					<!-- 등록된 프로젝트가 없을 경우 -->
					<tr v-if="pageInfo.totalRecordCount == 0">
						<td colspan="7" class="none">프로젝트가 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div><!-- //main-contents -->
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	components: { pagingComp },
	data() {
		return {
			input : {
				searchGubun : '1',		// 1:프로젝트명, 2:담당자명, 3:매니저명, 4:수요기업명
				projectStatus : [],		// 1:수행중, 2:수행예정, 9:완료
				projectStatusTemp : '',
				searchKeyword: '',
				projectDivCd: '1', // 1: 일반구인 프로젝트, 2: 공급기업지정 프로젝트
				projectApprStatus:''
			},
			
			nowYyyymmdd : '',

			projectList : {},
			pageInfo : {},
		};
	},
	mounted(){
			
		this.nowYyyymmdd = new Date().format("yyyyMMdd");

		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}

		//매니저 검색 결과
		const mangerPrjQuery = this.$route.query

		if(mangerPrjQuery.searchGubun){
			this.input.searchGubun = mangerPrjQuery.searchGubun;
		}
		if(mangerPrjQuery.searchKeyword){
			this.input.searchKeyword = mangerPrjQuery.searchKeyword;
		}
		this.getList();
	},
	methods: {
        getList(div, isScroll = false){

			if(div) this.input.pageIndex = 1;

			this.input.projectStatus = [];

			if(this.input.projectStatusTemp != '') {
				this.input.projectStatus.push(this.input.projectStatusTemp);
			}

            this.$.httpPost('/api/prj/adm/getProjectList', this.input)
                .then(res => {
                    this.projectList = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                    if(isScroll) {
						window.scroll(0, 0);
					}
				})
                .catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
        },   
		
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		clickProjectInfo(projectSeq) {

			var params = {
				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

			this.$router.push({name: 'PRJ910M02', query : {projectSeq : projectSeq}, params });

		},	
		
		// 수요기업의 프로젝트 담당자 정보 보기
		popupCorpMgrMber(corpMgrMberSeq) {
			var param = {};
			param.reqMberSeq = corpMgrMberSeq;
			param.div = 'pmg'; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},

		// 프로젝트 담당 매니저 정보 보기
		popupAdmMgerMber(admMgrMberSeq) {
			var param = {};
			param.reqMberSeq = admMgrMberSeq;
			param.div = 'agt'; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},
		
		// 매니저 배정하기
		setAgent(projectSeq, projectNm) {
			if(this.$store.state.userInfo.mberDivCd != '00') return alert('총괄관리자만 매니저 배정이 가능합니다.');
			var params = {

				projectSeq : projectSeq,
				projectNm : projectNm,
				gubun : 'new',

				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

			this.$router.push({name: 'PRJ910M06', params });
		}
	},
};
</script>
